import React, { useEffect, useState } from 'react';
import MetaMaskOnboarding from '@metamask/onboarding'
import locking from "./locking"
import Web3 from "web3";
import Locks from "./Locks";
import utils from "./utils";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  let onboardButton;
  let loadDataButton;
  let claimableBalanceText;
  let lockedBalanceText;
  let claimButton;

  let contract;
  let web3;
  let initial_load = false;

  const [account, setAccount] = useState(undefined);
  const [onboarding, setOnboarding] = useState('');
  const [locks, setLocks] = useState([]);
  const [chain, setChain] = useState(0);
  const [initialized_vars, setInitializedVars] = useState(false);


  useEffect(() => {
    const interval = setInterval(async () => {
      await init_vars()
      await checkMetamaskStatus()
      if (!initial_load) await loadAllData()
    }, 1000);
    return () => clearInterval(interval);
  });

  const init_vars = async () => {
    if (!initialized_vars)
    console.log('init app')

    onboardButton = document.getElementById('connectButton')
    loadDataButton = document.getElementById('loadDataButtonButtonID')
    loadDataButton.onclick = loadAllData
    claimableBalanceText = document.getElementById('claimableBalanceTextID')
    lockedBalanceText = document.getElementById('lockedBalanceTextID')
    claimButton = document.getElementById('claimButtonID')
    claimButton.onclick = claimTokens

    if (account !== undefined) {
      window.ethereum.on('accountsChanged', async (accounts) => {
        setAccount(accounts[0])
        await loadAllData()
      });

      window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
      });
      setInitializedVars(true)
    }

  }

  const checkMetamaskStatus = async () => {
    if (!Boolean(window.ethereum && window.ethereum.isMetaMask)) {
      onboardButton.innerText = 'Install MetaMask!'
      onboardButton.onclick = onClickInstall
      onboardButton.disabled = false
    } else if (account) {
      onboardButton.innerText = account.substring(0, 4) + '...' +  account.substring(account.length-4, account.length)
      onboardButton.disabled = true
      if (web3 === undefined) web3 = new Web3(window.ethereum)
      if (contract === undefined && chain === 1) {
        contract = new web3.eth.Contract(locking.abi, locking.address_mainnet)
      } else if (contract === undefined && chain === 137) {
        contract = new web3.eth.Contract(locking.abi, locking.address_polygon)
      } else if (contract === undefined && chain === 80001) {
        contract = new web3.eth.Contract(locking.abi, locking.address_test)
      } else if (chain !== 0 && chain !== 1 && chain !== 137 && chain !== 80001)  {
        onboardButton.innerText = 'Please, change to Mainnet or Polygon network'
      }
      enableDisableButtons(true)
      if (onboarding) {
        onboarding.stopOnboarding()
      }
    } else {
      onboardButton.innerText = 'Connect'
      onboardButton.onclick = onClickConnect
      onboardButton.disabled = false
      enableDisableButtons(false)
    }
  }

  const onClickInstall = () => {
    onboardButton.innerText = 'Onboarding in progress'
    onboardButton.disabled = true
    setOnboarding(new MetaMaskOnboarding())
    onboarding.startOnboarding()
  }

  const onClickConnect = async () => {
    try {
      const newAccounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      setAccount(newAccounts[0])
      setChain(parseInt(window.ethereum.networkVersion))
      await checkMetamaskStatus()
      await loadAllData()
    } catch (error) {
      console.error(error)
    }
  }

  const enableDisableButtons = (state) => {
    // enable = true
    loadDataButton.disabled = !state
    if (!state) {
      claimButton.disabled = true
      claimableBalanceText.innerText = ""
      lockedBalanceText.innerText = ""
    }
  }

  const loadAllData = async () => {
    if (!initial_load) initial_load = true
    if (contract === undefined) return
    let claimable = await contract.methods.claimableTokens(account).call()
    claimableBalanceText.innerText = utils.fN(claimable)
    claimButton.disabled = parseInt(claimable) === 0

      lockedBalanceText.innerText = utils.fN(await contract.methods.addressLockedTokens(account).call())

    let lock_ids = await contract.methods.lockeds(account).call()
    let locks_list = []
    for (var i = 0; i < lock_ids.length; i++) {
      let lock = await contract.methods.locks(lock_ids[i]).call()
      locks_list.push(lock)
    }
    setLocks(locks_list)
  }

  const claimTokens = async () => {
    let tx = await contract.methods.claimTokens().send({from: account})
    console.log('claim tokens TxHash:', tx)
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-2">
              <img src="logo.png" alt="" className="logo"/>
            </div>
            <div className="col-sm-10">
              <a href="https://continuum.world" className="btn btn-menu btn-lg btn-block mb-3">PRE-REGISTER</a>
              <a href="https://continuum.world/#the-game" className="btn btn-menu btn-lg btn-block mb-3">THE GAME</a>
              <a href="https://whitepaper.continuum.world" className="btn btn-menu btn-lg btn-block mb-3">WHITEPAPER</a>
              <a href="https://continuum.world/#the_team" className="btn btn-menu btn-lg btn-block mb-3">TEAM</a>
              <a href="https://staking.continuum.world" className="btn btn-menu btn-lg btn-block mb-3 staking">LOCKING</a>
              <button className="btn btn-primary btn-lg btn-block mb-3 btn-connect" id="connectButton" disabled>Checking...</button>
            </div>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-7 sub-title">
                <img src="um.png" alt="" className="img-coin"/><span>$UM Tokens for claim</span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-7 reload-data">
                <span>Reload data</span>
                <button id="loadDataButtonButtonID" disabled>
                  <img src="load.png" alt="" className="img-coin"/>
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-8 locks-info">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col text">Claimable tokens:</div>
                      <div className="col"><span id="claimableBalanceTextID"> </span></div>
                    </div>
                    <div className="row">
                      <div className="col text gray-text">Locked balance:</div>
                      <div className="col gray-text"><span id="lockedBalanceTextID"> </span></div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <button className="btn btn-primary" id="claimButtonID" disabled>Claim Tokens</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="row table-title">
                <div className="col">
                  <span>Locked Tokens List</span>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-8 locks-list">
                <div className="row">
                  <div className="col">
                    <div className="row titles">
                      <div className="col-sm-3">Month</div>
                      <div className="col gray-text">Locked</div>
                      <div className="col">Claimed</div>
                      <div className="col">Pending</div>
                    </div>
                    <Locks title="Locks List" locks={locks} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-7 bottom-text gray-text">
                <ul>
                  <li>* Information shown above changes every month, with the new locked tokens for each month.</li>
                  <li>* You don't need to claim daily, you can claim whenever you want.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
