module.exports = {
  fN: function (number) {
    return numberWithCommas(new Intl.NumberFormat().format((number/10**18).toFixed(3)))
  },
  formatTime: function (timestamp) {
    var date = toUTC(timestamp * 1000)
    console.log(timestamp, date)
    return `${date.toLocaleString('EN', { month: 'short' })}. ${date.getFullYear()}`
  }
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function toUTC(myDate) {
  var d = new Date(myDate)
  return new Date(myDate+d.getTimezoneOffset()*60000)
}