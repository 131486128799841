import utils from "./utils";

function ListItem(props) {
  return <div className="row">
    <div className="col-sm-3">{utils.formatTime(props.value.start_claim)}</div>
    <div className="col gray-text">{utils.fN(props.value.amount)}</div>
    <div className="col">{utils.fN(props.value.claimed)}</div>
    <div className="col">{utils.fN(props.value.amount - props.value.claimed)}</div>
  </div>;

}

const Locks = (props) => {
  let keyCount = 0;
  console.log(props)
  function getKey(){
    return keyCount++;
  }

  return (
    <div>
      {props.locks.map((lock) =>
        <ListItem key={getKey()}
                  value={lock} />
      )}
    </div>
  );
}

export default Locks